import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@shared/notification/notification.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

enum ErrorCodes {
  Unreachable = 0,
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notification: NotificationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = this.getErrorMessage(error);

        if (errorMessage) {
          this.notification.show('error', errorMessage);
        }

        return throwError(() => error);
      })
    );
  }

  private getErrorMessage(error: HttpErrorResponse) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else if (error.status === ErrorCodes.Unreachable) {
      errorMessage = 'Unable to connect to the server';
    } else {
      errorMessage = `${error.status}: ${error.error.name}`;
    }

    return errorMessage;
  }
}
