import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { ElementId } from '@storykit/constants';

@Component({
  selector: 'app-advanced-section',
  templateUrl: './advanced-section.component.html',
  styleUrls: ['./advanced-section.component.scss'],
})
export class AdvancedSectionComponent {
  @Input() form!: UntypedFormGroup;
  @Input() formLoading!: boolean;
  @Input() blocked!: boolean;
  @Input() flaggedForDeletion!: boolean;
  @Input() ipBlocked!: boolean;
  @Input() userId$!: Observable<string | undefined>;
  @Input() resetLink$!: Observable<string>;
  @Input() ipBlock$!: Observable<boolean>;
  @Output() block = new EventEmitter();
  @Output() checkIpBlock = new EventEmitter();
  @Output() removeIpBlock = new EventEmitter();
  @Output() removeDeletedStatus = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() resetPassword = new EventEmitter();

  elementId = ElementId;
}
