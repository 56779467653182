<mat-table
  [dataSource]="clientDataSource()"
  [trackBy]="trackClientsByFn"
  class="client-table"
  [skElementId]="e2e.AgencyTableAgencyClients"
>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      [skElementId]="e2e.AgencyClientsTableRowName"
    >
      {{ element.client.name }}
      @if (element.new || element.updated || element.deleted) {
        <span class="client-unsaved-change">
          <pre> !</pre>
        </span>
      }
    </mat-cell>
  </ng-container>
  <form [formGroup]="hubspotControls" class="u-marginTm">
    <ng-container matColumnDef="hubspotId">
      <mat-header-cell *matHeaderCellDef>
        Hubspot Company Id *
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element; let i = index"
        [skElementId]="e2e.AgencyInputFieldHubspotCompanyForClient"
      >
        <mat-form-field
          subscriptSizing="dynamic"
          appearance="fill"
          color="primary"
        >
          <input
            type="text"
            matNativeControl
            formControlName="hubspotCompanyId{{ i }}"
            placeholder="ID"
            autocomplete="off"
            (keyup)="saveClient('hubspotCompanyId' + i, i)"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>
  </form>
  <ng-container matColumnDef="delete">
    <mat-header-cell *matHeaderCellDef class="delete-column">
      Delete
    </mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index" class="delete-column">
      <button
        mat-icon-button
        type="button"
        (click)="deleteClient(element, i)"
        [skElementId]="e2e.ClientButtonDelete"
      >
        <app-icon
          name="trash"
          weight="fill"
          class="u-color--negative u-iconsize--m o-btn__icon"
        ></app-icon>
      </button>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="link">
    <mat-header-cell *matHeaderCellDef class="delete-column u-paddingLxxs">
      Link
    </mat-header-cell>
    <mat-cell *matCellDef="let clientTableEntry" class="delete-column">
      @if (!clientTableEntry.new) {
        <a
          mat-icon-button
          [href]="generateClientLinkCreate(clientTableEntry)"
          target="_blank"
        >
          <app-icon
            name="link-simple-horizontal"
            class="o-btn__icon u-iconsize--m"
          ></app-icon>
        </a>
      }
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="clientTableColumns"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: clientTableColumns"
    [hidden]="row.deleted"
    [skElementId]="e2e.AgencyClientsTableRow"
    [skElementIdIndex]="row.index"
  ></mat-row>
</mat-table>
<form [formGroup]="clientForm" class="u-marginTm">
  <mat-label class="o-form-label u-marginT">Create a new client</mat-label>
  <div class="client-form">
    <mat-form-field class="u-marginRxs">
      <input
        type="text"
        matInput
        formControlName="name"
        placeholder="Name"
        autocomplete="off"
        (blur)="clientInputBlur()"
        [skElementId]="e2e.AgencyInputFieldNewClientName"
      />
    </mat-form-field>
    <button
      [disabled]="clientForm.invalid"
      mat-flat-button
      color="primary"
      (click)="addClient()"
      [skElementId]="e2e.AgencyButtonCreateClient"
    >
      Create Client
    </button>
  </div>
</form>
