import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AUTH_HEADER, SKIP_AUTH_HEADER } from '@storykit/constants';

import { AuthService } from './auth.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  /**
   * Intercepts all requests with Authorization header when token exists.
   * When it's not needed to add Authorization just add `x-skip-auth` header,
   * the header itself will be removed and not sent.
   */

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.authService.getAuthorizationHeader()).pipe(
      switchMap((bearerToken) => {
        if (bearerToken && !request.headers.has(SKIP_AUTH_HEADER)) {
          const authorizationRequest = request.clone({
            setHeaders: { [AUTH_HEADER]: bearerToken },
          });

          return next.handle(authorizationRequest);
        }

        request = request.clone({
          headers: request.headers.delete(SKIP_AUTH_HEADER),
        });

        return next.handle(request);
      })
    );
  }
}
