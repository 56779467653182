@if (feeds().length > 0) {
  <table mat-table [dataSource]="feedsDataSource()">
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Client</th>
      <td mat-cell *matCellDef="let element">{{ element.client }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Feed</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Admin</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          style="margin-right: 4px"
          (click)="openEditForm(element._feed)"
          [disabled]="editingFeed() !== null"
        >
          Edit
        </button>
        <button mat-raised-button (click)="deleteFeed(element, confirmDelete)">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <ng-template #confirmDelete let-data
    ><h2 mat-dialog-title>Delete feed '{{ data.name }}'?</h2>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button [mat-dialog-close]="true">Yes</button>
    </mat-dialog-actions>
  </ng-template>
}

@if (editingFeed(); as feed) {
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>Edit feed</mat-panel-title>
    </mat-expansion-panel-header>
    <app-edit-feed-form
      [agencyId]="agencyId()"
      [feedToEdit]="feed"
      (feedUpdated)="onFeedUpdated($event)"
      (cancel)="editingFeed.set(null)"
    ></app-edit-feed-form>
  </mat-expansion-panel>
}

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Set up new Feed</mat-panel-title>
  </mat-expansion-panel-header>
  <app-add-feed-form
    [agencyId]="agencyId()"
    [clients]="clients()"
    (feedCreated)="onFeedCreated($event)"
  ></app-add-feed-form>
</mat-expansion-panel>
