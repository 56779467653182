import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  MatError,
  MatFormField,
  MatInput,
  MatLabel,
} from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { SlideDefinitionLayoutService } from '@core/services/slide-definition-layout.service';
import { Modes } from '@models/modes';
import { SlideDefinition } from '@models/slide-definition';
import { ErrorComponent } from '@shared/error/error.component';
import { jsonValidator } from '@shared/form-validators/json-validator';
import { NotificationService } from '@shared/notification/notification.service';
import { SlideDefinitionNotification } from '@shared/notification/slide-definition-messages';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { combineLatest, lastValueFrom, of } from 'rxjs';
import {
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-slide-definition-layout',
  templateUrl: './slide-definition-layout.component.html',
  standalone: true,
  imports: [
    MatLabel,
    MatFormField,
    MatInput,
    MatError,
    ReactiveFormsModule,
    AsyncPipe,
    ErrorComponent,
  ],
  providers: [SlideDefinitionLayoutService],
})
export class SlideDefinitionLayoutComponent {
  modes = Modes;

  formLoading = false;

  mode$ = this.route.data.pipe(map(({ mode }) => mode as Modes));

  slideDefinitionLayoutId$ = this.route.params.pipe(
    map(({ slideDefinitionLayoutId }) =>
      slideDefinitionLayoutId ? String(slideDefinitionLayoutId) : undefined
    )
  );

  originalSlideDefinitionLayout: SlideDefinition | undefined;
  slideDefinitionLayout$ = this.slideDefinitionLayoutId$.pipe(
    switchMap((slideDefinitionLayoutId) =>
      slideDefinitionLayoutId
        ? this.slideDefinitionLayoutService
            .getSlideDefinitionLayout(slideDefinitionLayoutId)
            .pipe(
              tap((slideDefinitionLayout) => {
                if (slideDefinitionLayout) {
                  this.slideDefinitionLayoutForm.patchValue({
                    displayName: slideDefinitionLayout.displayName,
                    layout: JSON.stringify(
                      slideDefinitionLayout.layout,
                      null,
                      2
                    ),
                  });
                }
              })
            )
        : of(undefined)
    ),
    shareReplay(1)
  );

  loading$ = this.slideDefinitionLayout$.pipe(
    map(() => false),
    startWith(true)
  );

  slideDefinitionLayoutForm = new FormGroup({
    displayName: new FormControl('', Validators.required),
    layout: new FormControl('{}', [Validators.required, jsonValidator()]),
  });

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private slideDefinitionLayoutService: SlideDefinitionLayoutService,
    private breadcrumbsService: BreadcrumbsService,
    private notification: NotificationService,
    private dialog: MatDialog
  ) {
    combineLatest([this.slideDefinitionLayout$, this.mode$])
      .pipe(
        map(([layout, mode]) => {
          const name =
            mode === Modes.Add
              ? 'New Slide Definition Layout'
              : layout?.displayName || '';
          return [
            {
              label: 'Slide Definition Layouts',
              link: '/slide-definition-layouts',
            },
            { label: name },
          ];
        }),
        startWith([
          {
            label: 'Slide Definition Layouts',
            link: '/slide-definition-layouts',
          },
          { label: '' },
        ]),
        takeUntilDestroyed()
      )
      .subscribe((breadcrumbs) => {
        this.breadcrumbsService.set(breadcrumbs);
      });
  }

  async createSlideDefinitionLayout(): Promise<void> {
    try {
      this.formLoading = true;
      const slideDefinitionLayout = await lastValueFrom(
        this.slideDefinitionLayoutService.createSlideDefinitionLayout(
          this.slideDefinitionLayoutPayload()
        )
      );

      this.notification.show('success', SlideDefinitionNotification.Created);
      this.router.navigate([
        '/slide-definition-layouts',
        slideDefinitionLayout._id,
      ]);
    } catch (error) {
      this.notification.show('error', SlideDefinitionNotification.NotCreated);
    }
    this.formLoading = false;
  }

  async updateSlideDefinitionLayout(): Promise<void> {
    const slideDefinitionLayoutId = (await lastValueFrom(
      this.slideDefinitionLayoutId$.pipe(first())
    )) as string;
    this.formLoading = true;
    try {
      await lastValueFrom(
        this.slideDefinitionLayoutService.updateSlideDefinitionLayout(
          slideDefinitionLayoutId,
          this.slideDefinitionLayoutPayload()
        )
      );

      this.notification.show('success', SlideDefinitionNotification.Updated);
    } catch (error) {
      this.notification.show('error', SlideDefinitionNotification.NotUpdated);
    }

    this.formLoading = false;
  }

  async openDeleteDialog(): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'slide definition layout',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      await this.deleteSlideDefinitionLayout();
    }
  }

  private async deleteSlideDefinitionLayout(): Promise<void> {
    const slideDefinitionLayoutId = (await lastValueFrom(
      this.slideDefinitionLayoutId$.pipe(first())
    )) as string;

    try {
      await lastValueFrom(
        this.slideDefinitionLayoutService.deleteSlideDefinitionLayout(
          slideDefinitionLayoutId
        )
      );
      this.notification.show('success', SlideDefinitionNotification.Deleted);
      this.router.navigate(['/slide-definition-layouts']);
    } catch (error) {
      this.notification.show('error', SlideDefinitionNotification.NotDeleted);
    }
  }

  private slideDefinitionLayoutPayload() {
    return {
      displayName:
        this.slideDefinitionLayoutForm.get('displayName')?.value ?? '',
      layout: JSON.parse(
        this.slideDefinitionLayoutForm.get('layout')?.value ?? '{}'
      ),
    };
  }
}
