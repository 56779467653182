import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { SlideDefinitionLayoutService } from '@core/services/slide-definition-layout.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { VideoSlideDefinitionLayout } from '@storykit/typings/src/cws';

type VideoSlideDefinitionLayout =
  VideoSlideDefinitionLayout.VideoSlideDefinitionLayout;

@Injectable()
export class SlideDefinitionLayoutListDataSource extends DataSource<VideoSlideDefinitionLayout> {
  private totalCount$ = new BehaviorSubject<number>(0);

  constructor(
    private slideDefinitionLayoutService: SlideDefinitionLayoutService
  ) {
    super();
  }

  connect(): Observable<VideoSlideDefinitionLayout[]> {
    return this.slideDefinitionLayoutService.listSlideDefinitionLayouts().pipe(
      debounceTime(500),
      tap((data) => {
        this.totalCount$.next(data.length);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}
}
