import { CdkTableDataSourceInput } from '@angular/cdk/table';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Signal,
  TemplateRef,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { Client } from '@models/client';
import { AddFeedFormComponent } from '@views/agency/agency/feeds/add-feed-form/add-feed-form.component';
import { EditFeedFormComponent } from '@views/agency/agency/feeds/edit-feed-form/edit-feed-form.component';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { FeedMetadata, FeedView } from '@storykit/typings/src/cws/feed';

import { FeedsService } from './feeds.service';

@Component({
  selector: 'app-admin-feeds',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AddFeedFormComponent,
    EditFeedFormComponent,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRow,
    MatRow,
    MatHeaderRowDef,
    MatRowDef,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
  ],
  templateUrl: './feeds.component.html',
  styleUrl: './feeds.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FeedsService],
})
export class FeedsComponent {
  agencyId = input.required<string>();
  clients = input.required<Client[]>();

  service = inject(FeedsService);
  destroyRef = inject(DestroyRef);
  dialog = inject(MatDialog);
  elementRef = inject(ElementRef);

  private refreshFeedsForAgency$ = new Subject<string>();

  feeds = signal<FeedMetadata[]>([]);
  editingFeed = signal<FeedMetadata | null>(null);

  constructor() {
    effect(() => {
      const agencyId = this.agencyId();
      this.loadFeeds(agencyId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    });
  }

  private loadFeeds(agencyId: string): Observable<FeedMetadata[]> {
    return this.service
      .getFeeds(agencyId)
      .pipe(tap((feeds) => this.feeds.set(feeds)));
  }

  displayedColumns = ['name', 'client', 'actions'];
  feedsDataSource: Signal<CdkTableDataSourceInput<FeedRow>> = computed<
    FeedRow[]
  >(() => {
    const clients = this.clients();
    const feeds = this.feeds();

    return feeds.map((feed) => {
      const client = clients.find((client) => client._id === feed.clientId);
      return {
        _feed: feed,
        client: client?.name ?? feed.clientId,
        name: feed.settings.name,
      };
    });
  });

  deleteFeed(element: FeedRow, confirmDelete: TemplateRef<FeedRow>) {
    this.dialog
      .open(confirmDelete, { data: element })
      .afterClosed()
      .pipe(
        filter((confirmResult) => confirmResult === true),
        switchMap(() =>
          this.service.deleteFeed(element._feed.clientId, element._feed._id)
        ),
        finalize(() => this.loadFeeds(this.agencyId())),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  onFeedCreated(_feed: FeedView) {
    this.loadFeeds(this.agencyId())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  onFeedUpdated(_feed: FeedView) {
    this.loadFeeds(this.agencyId())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.editingFeed.set(null);
      });
  }

  openEditForm(_feed: FeedView) {
    this.editingFeed.set(_feed);
  }
}

interface FeedRow {
  _feed: FeedMetadata;
  client: string;
  name: string;
}
