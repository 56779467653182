import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { CwsService } from '@core/services/cws.service';
import { Modes } from '@models/modes';
import { Access, User } from '@models/user';
import { NotificationService } from '@shared/notification/notification.service';
import { UserNotification } from '@shared/notification/user-messages';
import { UserAccessForm } from '@shared/user-access-form/user-access-form';
import { UserForm } from '@shared/user-form/user-form';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { Subject, combineLatest, lastValueFrom, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { ElementId } from '@storykit/constants';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  resetLinkSubject = new Subject<string>();
  ipBlockSubject = new Subject<boolean>();

  userId$ = this.route.params.pipe(
    map(({ userId }) => (userId ? String(userId) : undefined))
  );

  modes = Modes;

  mode$ = this.route.data.pipe(map(({ mode }) => mode as Modes));

  formLoading = true;

  blocked = false;
  verified: boolean | undefined;

  flaggedForDeletion = false;

  form = this.userForm.createFormGroup();

  elementId = ElementId;

  accessForm = this.formBuilder.group({
    access: this.formBuilder.array([]),
  });

  user$ = this.userId$.pipe(
    switchMap((userId) =>
      userId ? this.cwsService.getUser(userId) : of(undefined)
    ),
    shareReplay(1)
  );

  resetLink$ = this.resetLinkSubject.asObservable();
  ipBlock$ = this.ipBlockSubject.asObservable();

  loading$ = this.user$.pipe(
    map(() => false),
    startWith(true)
  );

  clientsAgencies$ = this.cwsService.getClientsAgencies().pipe(
    catchError(() => {
      this.form.setErrors({ noData: true });
      this.notification.show('error', UserNotification.CwsFailed);

      return of({
        agencies: [],
        clients: [],
      });
    }),
    tap(() => (this.formLoading = false))
  );

  accessValues$ = combineLatest([this.user$, this.clientsAgencies$]).pipe(
    map(([user, { clients }]) =>
      this.userAccessForm.setAccessValues(user?.access, clients)
    )
  );

  accessCount = 0;

  private breadcrumbs$ = combineLatest([this.user$, this.mode$]).pipe(
    map(([user, mode]) => {
      const name = this.getDisplayName(mode, user);
      return [{ label: 'Users', link: '/users' }, { label: name }];
    }),
    startWith([{ label: 'Users', link: '/users' }, { label: '' }])
  );

  constructor(
    private formBuilder: FormBuilder,
    private cwsService: CwsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private notification: NotificationService,
    private breadcrumbsService: BreadcrumbsService,
    private userAccessForm: UserAccessForm,
    private userForm: UserForm
  ) {}
  ngOnInit(): void {
    this.breadcrumbs$.subscribe((breadcrumbs) => {
      this.breadcrumbsService.set(breadcrumbs);
    });

    this.user$.subscribe((user) => {
      if (user) {
        this.updateUserFormFields(user);
        this.blocked = user.blocked;
        if (user.deleted) this.flaggedForDeletion = true;
        this.verified = user.verified;
      }
    });

    this.accessForm.valueChanges.subscribe(() => {
      const accessValues = this.accessForm.value.access as Access[];
      this.accessCount = accessValues.length;
    });
  }

  getResetLink() {
    this.userId$.subscribe((userId) => {
      if (userId) {
        this.cwsService
          .getPasswordResetLink(userId)
          .pipe(tap(({ resetLink }) => this.resetLinkSubject.next(resetLink)))
          .subscribe();
      }
    });
  }

  checkIpBlock() {
    this.userId$.subscribe((userId) => {
      if (userId) {
        this.cwsService
          .checkUserIpBlock(userId)
          .pipe(tap(({ blocked }) => this.ipBlockSubject.next(blocked)))
          .subscribe();
      }
    });
  }

  removeIpBlock() {
    this.userId$.subscribe(async (userId) => {
      if (userId) {
        this.cwsService.removeUserIpBlock(userId).subscribe(() => {
          this.ipBlockSubject.next(false);
        });
      }
    });
  }

  removeDeletedStatus() {
    this.userId$.subscribe(async (userId) => {
      if (userId) {
        this.cwsService.removeDeletedStatus(userId).subscribe(() => {
          this.flaggedForDeletion = false;
        });
      }
    });
  }

  async updateUser() {
    if (!this.isValid()) {
      return;
    }

    const user = await lastValueFrom(this.user$.pipe(first()));

    this.formLoading = true;

    if (user) {
      try {
        const { _id, legacyId } = user;

        const formUser = {
          ...this.mapFormValues(),
          _id,
          ...(legacyId && { legacyId }),
        };

        await lastValueFrom(this.cwsService.updateUser(formUser));

        this.updateUserFormFields(formUser);
        this.notification.show('success', UserNotification.Updated);
      } catch (error) {
        this.notification.show('error', UserNotification.NotUpdated);
      }

      this.formLoading = false;
    } else {
      this.notification.show('error', UserNotification.NotUpdated);
      this.formLoading = false;
    }
  }

  async openDeleteDialog() {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'user',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      this.deleteUser();
    }
  }

  async openBlockDialog() {
    const action = this.blocked ? 'Unblock' : 'Block';
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action,
      subject: 'user',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      this.setBlocked(!this.blocked);
    }
  }

  async createUser() {
    if (!this.isValid()) {
      return;
    }

    try {
      await lastValueFrom(this.cwsService.createUser(this.mapFormValues()));
      this.notification.show('success', UserNotification.Created);
      this.router.navigate(['/users']);
    } catch (error) {
      const err = error as ErrorEvent;
      this.notification.show(
        'error',
        `${UserNotification.NotCreated} - ${err?.error?.message}`
      );
    }
  }

  async resendInviteEmail() {
    const userId = await lastValueFrom(this.userId$.pipe(first()));

    if (!userId) {
      return;
    }

    try {
      await lastValueFrom(this.cwsService.resendInviteEmail(userId));
      this.notification.show('success', UserNotification.InviteEmailSent);
    } catch (error) {
      this.notification.show('error', UserNotification.InviteEmailSentError);
    }
  }

  private isValid() {
    return this.form.valid && this.accessForm.valid;
  }

  private updateUserFormFields(user: User) {
    this.form.patchValue(user);
  }

  private mapFormValues(): User {
    const accessValues = this.accessForm.value.access as Access[];
    const user = this.form.value as User;

    const access = accessValues.map((formItem) => ({
      id: formItem.id,
      role: formItem.role,
      type: formItem.type,
    }));

    return { ...user, access };
  }

  private async deleteUser() {
    const userId = await lastValueFrom(this.userId$.pipe(first()));

    if (!userId) {
      return;
    }

    try {
      await lastValueFrom(this.cwsService.deleteUser(userId));
      this.notification.show('success', UserNotification.Deleted);
      this.router.navigate(['/users']);
    } catch (error) {
      this.notification.show('error', UserNotification.NotDeleted);
    }
  }

  private async setBlocked(blocked: boolean) {
    const userId = await lastValueFrom(this.userId$.pipe(first()));
    this.formLoading = true;

    if (!userId) {
      return;
    }

    try {
      await lastValueFrom(this.cwsService.setBlocked(userId, blocked));
      this.notification.show('success', UserNotification.Updated);
      this.blocked = blocked;
    } catch (error) {
      this.notification.show('error', UserNotification.NotUpdated);
    }

    this.formLoading = false;
  }

  private getDisplayName(mode: Modes, user?: User) {
    return mode === Modes.Add
      ? 'New User'
      : `${user?.firstName || ''} ${user?.lastName || ''}`;
  }
}
