<form [formGroup]="agencyForm" *ngIf="(loading$ | async) === false">
  <div class="container container--small u-marginTxxl u-flex">
    <div class="u-width--full u-flex u-flex-align--center">
      <h2>Agency</h2>
      <a
        mat-icon-button
        class="u-paddingTxxs"
        [href]="agencyLink"
        target="_blank"
      >
        <app-icon name="link-simple-horizontal" class="o-btn__icon"></app-icon>
      </a>
    </div>
  </div>

  <div class="container container--small">
    <div class="u-marginBm u-flex">
      <div class="u-marginRxs">
        <mat-label class="o-form-label">Name</mat-label>
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="name"
            placeholder="Name"
            autocomplete="off"
            [skElementId]="e2e.AgencyInputFieldName"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-label class="o-form-label">Hubspot Company Id *</mat-label>
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="hubspotCompanyId"
            placeholder="ID"
            autocomplete="off"
            [skElementId]="e2e.AgencyInputFieldHubspotCompanyId"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="u-marginBm u-flex">
      <div>
        <mat-checkbox
          formControlName="active"
          [skElementId]="e2e.AgencyCheckboxStatus"
          >Active
        </mat-checkbox>
        <div
          class="u-color--medium"
          *ngIf="
            (agency$ | async)?.active === false &&
            (agency$ | async)?.inactiveSince
          "
        >
          (Inactive Since: {{ (agency$ | async)?.inactiveSince | date }})
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="mode()">
      <div class="u-marginBs u-flex">
        <div class="u-marginRxs">
          <mat-label class="o-form-label">Customer Type</mat-label>
          <mat-form-field [skElementId]="e2e.AgencyDropdownCustomerType">
            <mat-select
              formControlName="customerType"
              [skElementId]="e2e.AgencyDropdownCustomerTypeList"
            >
              <mat-option
                *ngFor="let customer of customerTypeOptions; let i = index"
                value="{{ customer }}"
                [skElementId]="e2e.AgencyDropdownCustomerTypeItem"
                [skElementIdIndex]="i"
              >
                {{ customer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div formGroupName="featureSettings" class="u-marginRxs">
          <mat-label class="o-form-label"> Users Limit</mat-label>
          <mat-form-field
            formGroupName="maxUsersAmount"
            [skElementId]="e2e.AgencyInputFieldUsersLimit"
          >
            <input type="number" matInput formControlName="value" min="0" />
          </mat-form-field>
        </div>

        <div formGroupName="featureSettings">
          <mat-label class="o-form-label">Customer Admin</mat-label>
          <div formGroupName="customerAdmin">
            <mat-checkbox
              formControlName="value"
              [skElementId]="e2e.AgencyCheckboxCustomerAdmin"
            >
              Enable
            </mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <mat-sidenav-container>
    <mat-sidenav mode="side" opened>
      <mat-action-list>
        @if (mode() === modes.Edit) {
          <button
            mat-list-item
            [activated]="activeTab() === 'clients'"
            (click)="activeTab.set('clients')"
            [skElementId]="e2e.AgencyMenuItemClients"
          >
            <span class="unsaved-change">{{
              hasUnsavedClientChanges ? '!' : ''
            }}</span>
            Clients ({{ currentClients().length }})
          </button>
        }

        <button
          mat-list-item
          [activated]="activeTab() === 'premium-features'"
          (click)="activeTab.set('premium-features')"
          [skElementId]="e2e.AgencyMenuItemPremiumFeatures"
        >
          <span class="unsaved-change">{{
            hasUnsavedPremiumChanges ? '!' : ''
          }}</span>
          Premium Features
        </button>

        <button
          mat-list-item
          [activated]="activeTab() === 'free-trial'"
          (click)="activeTab.set('free-trial')"
        >
          <span class="unsaved-change">{{
            hasUnsavedFreeTrialChanges ? '!' : ''
          }}</span>
          Free Trial Settings
        </button>

        @if (mode() === modes.Edit) {
          <button
            mat-list-item
            [activated]="activeTab() === 'fonts'"
            (click)="activeTab.set('fonts')"
            [skElementId]="e2e.AgencyMenuItemFonts"
          >
            <span class="unsaved-change">{{
              hasUnsavedFontChanges ? '!' : ''
            }}</span>
            Fonts ({{ (fonts$ | async)?.length }})
          </button>
        }
        @if (mode() === modes.Edit && currentClients()) {
          <button
            mat-list-item
            [activated]="activeTab() === 'users'"
            (click)="activeTab.set('users')"
            [skElementId]="e2e.AgencyMenuItemUser"
          >
            <span class="unsaved-change">{{
              hasUnsavedFontChanges ? '!' : ''
            }}</span>
            Users ({{ (usersDataSource.users$ | async)?.users?.length }})
          </button>
        }
        @if (mode() === modes.Edit && canEditLanguageInstruction) {
          <button
            mat-list-item
            [activated]="activeTab() === 'script-creators'"
            (click)="activeTab.set('script-creators')"
          >
            Script Creators
          </button>
        }
        @if (mode() === modes.Edit) {
          <button
            mat-list-item
            [activated]="activeTab() === 'custom-talking-head-slide'"
            (click)="activeTab.set('custom-talking-head-slide')"
          >
            Custom Talking Head Slide
          </button>
        }
        @if (mode() === modes.Edit && currentClients() && canAdminFeeds) {
          <button
            mat-list-item
            [activated]="activeTab() === 'feeds'"
            (click)="activeTab.set('feeds')"
          >
            Feeds
          </button>
        }
      </mat-action-list>
    </mat-sidenav>
    <mat-sidenav-content style="padding: 24px">
      @switch (activeTab()) {
        @case ('clients') {
          <app-agency-clients
            [currentClients]="currentClients()"
            [agencyHubspotId]="agencyForm.value.hubspotCompanyId ?? ''"
            [hasUnsavedClientChanges]="hasUnsavedClientChanges"
            (hasUnsavedClientChangesChange)="hasUnsavedClientChanges = $event"
            (clientsToCreate)="clientsToCreate = $event"
            (clientsToUpdate)="clientsToUpdate = $event"
            (clientsToDelete)="clientsToDelete = $event"
          ></app-agency-clients>
        }
        @case ('premium-features') {
          <app-premium-features-form
            [form]="agencyFeatureSettingsFormGroup"
          ></app-premium-features-form>
        }
        @case ('free-trial') {
          <app-free-trial-form [form]="agencyForm"></app-free-trial-form>
        }
        @case ('fonts') {
          <app-font-calc-list
            [agencyId]="(agencyId$ | async)!"
            [fonts]="(fonts$ | async)!"
            [clients]="currentClients()"
            (hasUnsavedFontChanges)="fontChangesHandler($event)"
          ></app-font-calc-list>
        }
        @case ('users') {
          <app-user-list [usersDataSource]="usersDataSource"></app-user-list>
        }
        @case ('script-creators') {
          <mat-form-field formGroupName="settings">
            <mat-label
              >Language Instruction (Do not use without approval from Product
              Department)
            </mat-label>
            <textarea
              matInput
              formControlName="scriptCreatorLanguageInstruction"
            ></textarea>
          </mat-form-field>
        }
        @case ('custom-talking-head-slide') {
          <mat-form-field formGroupName="settings">
            <mat-label>Custom slide definition type name</mat-label>
            <input
              matInput
              type="text"
              formControlName="customTalkingHeadSlide"
              placeholder="talking_head_storytelling_company"
            />
          </mat-form-field>
        }
        @case ('feeds') {
          <app-admin-feeds
            [agencyId]="(agencyId$ | async)!"
            [clients]="currentClients()"
          ></app-admin-feeds>
        }
      }
    </mat-sidenav-content>
  </mat-sidenav-container>

  <ng-container [ngSwitch]="mode()">
    <ng-container *ngSwitchCase="modes.Add">
      <div class="container container--small u-marginTl u-paddingBxl">
        <button
          [disabled]="formLoading || agencyForm.invalid"
          mat-flat-button
          data-test-id="create-agency-button"
          color="primary"
          (click)="createAgency()"
          [skElementId]="e2e.AgencyButtonCreateAgency"
        >
          Create Agency
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="modes.Edit">
      <div class="container container--small warning-unsaved-changes">
        <span
          *ngIf="
            hasUnsavedClientChanges ||
            hasUnsavedFontChanges ||
            hasUnsavedFormChanges
          "
          >Unsaved Changes!</span
        >
      </div>
      <div class="container container--small u-paddingBxl">
        <button
          [disabled]="formLoading || agencyForm.invalid || hasExpiredTrial"
          mat-flat-button
          data-test-id="update-agency-button"
          color="primary"
          (click)="updateAgency()"
          [skElementId]="e2e.AgencyButtonUpdateAgency"
        >
          Update Agency
        </button>
        <button
          mat-flat-button
          [disabled]="formLoading"
          data-test-id="delete-agency-button"
          color="warn"
          class="u-marginLs"
          (click)="openDeleteDialog()"
          [skElementId]="e2e.AgencyButtonDeleteAgency"
        >
          Delete Agency
        </button>
        <button
          mat-stroked-button
          [disabled]="formLoading || !hasExpiredTrial"
          data-test-id="restart-agency-button"
          color="primary"
          class="u-marginLs"
          (click)="openRestartTrialDialog()"
          [skElementId]="e2e.AgencyButtonRestartTrial"
        >
          Reactivate Trial Agency
        </button>
      </div>
    </ng-container>
  </ng-container>
</form>
<app-error [observable$]="agency$"></app-error>
