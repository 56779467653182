import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { StatusCodes } from '@core/observable-queue/observable-queue';
import {
  languageLabels,
  languages,
  userRoleLabels,
  userRoles,
} from '@models/user';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-bulk-table',
  templateUrl: './user-bulk-table.component.html',
  styleUrls: [
    '../../partials/table/table.scss',
    '../user-list/user-list-table.scss',
  ],
})
export class UserBulkTableComponent implements OnInit {
  @Input() form!: UntypedFormGroup;

  dataSource = new BehaviorSubject<AbstractControl[]>([]);

  statusCodes = StatusCodes;
  userRoles = userRoles;
  userRoleLabels = userRoleLabels;
  languages = languages;
  languageLabels = languageLabels;

  displayedColumns = [
    'firstName',
    'lastName',
    'email',
    'role',
    'language',
    'remove',
    'status',
  ];

  ngOnInit(): void {
    this.updateView();
    this.form.valueChanges.subscribe(() => {
      this.updateView();
    });
  }

  deleteUserRow(rowIndex: number) {
    this.getUserArray().removeAt(rowIndex);
  }

  private updateView() {
    this.dataSource.next(this.getUserArrayControls());
  }

  private getUserArrayControls() {
    return this.getUserArray().controls;
  }

  private getUserArray() {
    return this.form.get('users') as UntypedFormArray;
  }
}
