<div class="container u-marginTxxl u-flex u-marginBm">
  <div class="u-marginRs">
    <mat-label class="o-form-label">Search</mat-label>
    <mat-form-field class="u-marginRxs">
      <input
        #search
        data-test-id="user-list-search"
        [value]="(usersDataSource.filters$ | async)?.search || ''"
        (input)="usersDataSource.updateSearch({ search: search.value })"
        type="text"
        matInput
        placeholder="Search"
        [skElementId]="elementId.Admin.UserSearchField"
      />
      <button
        *ngIf="search.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
      >
        <app-icon name="x" class="u-iconsize--s o-btn__icon"></app-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="u-marginRs">
    <mat-label class="o-form-label">Filter role</mat-label>
    <mat-form-field class="u-marginRxs">
      <mat-select
        [value]="(usersDataSource.filters$ | async)?.role"
        (selectionChange)="usersDataSource.updateRole($event.value)"
      >
        <mat-option value="">All</mat-option>
        <mat-option *ngFor="let role of userRoles" value="{{ role }}">
          {{ userRoleLabels[role] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="u-marginRs">
    <mat-label class="o-form-label">Filter deleted</mat-label>
    <mat-checkbox
      class="u-marginAxxs"
      [ngModel]="includeDeleted"
      (ngModelChange)="setIncludeDeleted($event)"
      [skElementId]="elementId.Admin.UserCheckboxDeleted"
      >Enable</mat-checkbox
    >
  </div>
  <div class="align-right">
    <button
      mat-raised-button
      color="primary"
      [matMenuTriggerFor]="menu"
      [skElementId]="elementId.Admin.ButtonUserActions"
    >
      User actions
    </button>
    <mat-menu #menu="matMenu">
      <div [skElementId]="elementId.Admin.UserDropdownMenu">
        <a
          [routerLink]="['/users/add']"
          class="u-flex u-flex-align--center"
          mat-button
          [skElementId]="elementId.Admin.ButtonCreateUser"
        >
          <app-icon
            name="user"
            weight="fill"
            class="u-marginRs u-iconsize--m o-btn__icon"
          ></app-icon>

          <span>Create user</span>
        </a>
        <a
          [routerLink]="['/users/bulk/add']"
          class="u-flex u-flex-align--center"
          mat-button
        >
          <app-icon
            name="users-three"
            weight="fill"
            class="u-marginRs u-iconsize--s o-btn__icon"
          ></app-icon>
          <span>Bulk create users</span>
        </a>
      </div>
    </mat-menu>
  </div>
</div>

<div class="container u-paddingBxxl">
  <app-user-list [usersDataSource]="usersDataSource"></app-user-list>
</div>
