import { AsyncPipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { User, userRoleLabels } from '@models/user';
import { MATERIAL_MODULES } from '@shared/material-design/material-modules';
import { UserListDataSource } from '@views/user/user-list/user-list.dataSource';

import { ElementId } from '@storykit/constants';
import { ElementIdDirective } from '@storykit/ui-components';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: [
    '../../views/partials/table/table.scss',
    './user-list-table.scss',
  ],
  standalone: true,
  imports: [MATERIAL_MODULES, ElementIdDirective, RouterLink, AsyncPipe],
})
export class UserListComponent {
  usersDataSource = input.required<UserListDataSource>();

  userRoleLabels = userRoleLabels;

  elementId = ElementId;

  displayedColumns = [
    'firstName',
    'lastName',
    'email',
    'role',
    'actions',
    'blocked',
  ];

  assertUser(user: any): User {
    return user;
  }
}
