<app-global-progress></app-global-progress>
<div class="c-navbar level1">
  <a
    class="c-navbar__brand"
    [ngClass]="{ 'c-navbar__brand--disabled': !authenticated }"
    href="/"
  >
    <img src="assets/logo/logo-small.png" />
  </a>

  <span [ngClass]="['u-marginLs', 'environment', environment]">
    {{ capitalize(environment) }}
  </span>

  <nav class="o-breadcrumbs u-flex--fluid u-paddingHm">
    <span class="o-breadcrumbs__item">
      <span class="o-breadcrumbs__item brand">
        <span class="u-paddingRxs">ADMIN</span>
      </span>
    </span>
    <span
      class="o-breadcrumbs__item"
      *ngFor="let breadcrumb of breadcrumbsService.breadcrumbs$ | async"
    >
      <a
        *ngIf="breadcrumb.link"
        class="u-text-truncate"
        [routerLink]="breadcrumb.link"
        [skElementId]="elementId.Admin.BreadcrumbLink"
      >
        {{ breadcrumb.label }}
      </a>
      <span
        *ngIf="!breadcrumb.link"
        class="o-breadcrumbs__item u-text-truncate u-color--light"
      >
        <span class="u-paddingRxs">
          {{ breadcrumb.label }}
        </span>
      </span>
    </span>
  </nav>

  <div class="spacer"></div>

  <ng-container *ngIf="authenticated">
    <a
      [routerLink]="['/users']"
      mat-flat-button
      color="secondary"
      [skElementId]="elementId.Admin.HeaderMenuItemUsers"
    >
      Users
    </a>
    <a
      [routerLink]="['/agencies']"
      mat-flat-button
      color="secondary"
      [skElementId]="elementId.Admin.HeaderMenuItemAgencies"
    >
      Agencies
    </a>
    <a
      [routerLink]="['/slide-definitions']"
      mat-flat-button
      color="secondary"
      *ngIf="vsdpAccess"
    >
      Slide Definitions
    </a>
    <a
      [routerLink]="['/slide-definition-layouts']"
      mat-flat-button
      color="secondary"
      *ngIf="vsdpAccess"
    >
      Slide Definition Layouts
    </a>

    |

    <button (click)="logout()" mat-flat-button color="secondary">
      Log out
    </button>
  </ng-container>
</div>
