import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class NotAuthenticatedGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isAuthorized().pipe(
      switchMap((isAuthorized) => {
        if (!isAuthorized) {
          return of(true);
        }

        return this.authService.currentUser$.pipe(
          map((user) => {
            if (user?.role !== 'administrator') {
              return true;
            }

            return this.router.parseUrl('');
          })
        );
      })
    );
  }
}
