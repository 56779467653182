import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Agency } from '@models/agency';
import { AgencyListQuery } from '@models/agency-filter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TOTAL_HEADER } from '@storykit/constants';
import { Cws } from '@storykit/typings';
import { ApiService } from '@storykit/ui-components';

interface ListResponse {
  agencies: Agency[];
  totalCount: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  readonly baseUrl = `${environment.api.cws.endpoint}/agency`;

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService
  ) {}

  getAgencies(
    agencyListQuery: Partial<AgencyListQuery> = {}
  ): Observable<ListResponse> {
    return this.apiService
      .call<Cws.ListAgencies>({
        origin: environment.api.cws.endpoint,
        path: '/agency',
        method: 'GET',
        params: {},
        query: this.getListAgencyQuery(agencyListQuery),
        body: null,
      })
      .pipe(
        map(({ body, headers }) => {
          const agencies = (body ?? []) as Agency[];

          return {
            agencies,
            totalCount: headers.get(TOTAL_HEADER)
              ? Number(headers.get(TOTAL_HEADER))
              : 0,
            pageSize: agencyListQuery.pageSize ?? agencies.length,
          };
        })
      );
  }

  getAgency(agencyId: string) {
    return this.apiService
      .call<Cws.GetAgency>({
        origin: environment.api.cws.endpoint,
        path: '/agency/:agencyId',
        method: 'GET',
        params: { agencyId },
        query: null,
        body: null,
      })
      .pipe(map((res) => res.body as Agency));
  }

  createAgency(agency: Omit<Agency, '_id'>): Observable<Agency> {
    const validAgency = this.sanitizeAgency(agency);
    return this.httpClient.post<Agency>(this.baseUrl, validAgency);
  }

  updateAgency({ _id, ...agency }: Agency): Observable<Agency> {
    const validAgency = this.sanitizeAgency(agency);
    return this.httpClient.put<Agency>(`${this.baseUrl}/${_id}`, validAgency);
  }

  deleteAgency(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }

  sanitizeAgency(agency: Omit<Agency, '_id'>) {
    if (agency.freeTrialExpirationDate === null) {
      delete agency.freeTrialExpirationDate;
    }

    return agency;
  }

  private getListAgencyQuery({
    active,
    customerType,
    pageIndex,
    pageSize,
    search,
  }: Partial<AgencyListQuery> = {}): Cws.ListAgencies['query'] {
    const params: Cws.ListAgencies['query'] = {
      orderBy: 'name',
      order: 'asc',
      active: 'all',
    };

    if (active !== undefined && active !== '') {
      params.active = String(active);
    }

    if (customerType !== undefined && customerType !== '') {
      params.customerType = String(customerType);
    }

    if (pageIndex !== undefined) {
      params.page = String(pageIndex);
    }

    if (pageSize !== undefined) {
      params.limit = String(pageSize);
    }

    if (search !== undefined && search !== '') {
      params.search = search;
    }

    return params;
  }
}
