<form [formGroup]="slideDefinitionForm" *ngIf="(loading$ | async) === false">
  <div class="container container--small u-marginTxxl u-flex">
    <div class="u-width--full u-flex u-flex-align--center">
      <h1>Slide Definition</h1>
    </div>
  </div>

  <div class="container container--small">
    <div class="u-marginBm u-flex">
      <div>
        <mat-label class="o-form-label">Display name</mat-label>
        <mat-form-field class="u-marginRxs">
          <input
            type="text"
            matInput
            formControlName="displayName"
            placeholder="Display name"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-label class="o-form-label">Definition Group</mat-label>
        <mat-form-field class="u-marginRxs">
          <mat-select formControlName="definitionGroup">
            <mat-option
              *ngFor="let slideDefinitionGroup of slideDefinitionGroups"
              value="{{ slideDefinitionGroup._id }}"
            >
              {{ slideDefinitionGroup.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="u-marginBm u-flex">
      <div>
        <mat-label class="o-form-label">Defintition type</mat-label>
        <mat-form-field class="u-marginRxs">
          <mat-select
            formControlName="toggleDefinitionTypeSelect"
            #definitionTypeSelect
          >
            <mat-option
              *ngFor="let toggleDefinitionTypeSelect of ['New', 'Keep']"
              value="{{ toggleDefinitionTypeSelect }}"
            >
              {{ toggleDefinitionTypeSelect }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="definitionTypeSelect.value === 'New'">
        <mat-label class="o-form-label">New Definition Type</mat-label>
        <mat-form-field class="u-marginRxs">
          <input
            type="text"
            matInput
            formControlName="definitionTypeName"
            placeholder="Name"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div *ngIf="definitionTypeSelect.value === 'Keep'">
        <mat-label class="o-form-label">Choose existing</mat-label>
        <mat-form-field class="u-marginRxs">
          <mat-select formControlName="definitionType" #definitionTypeSelect>
            <mat-option
              *ngFor="let slideDefinitionType of slideDefinitionTypes"
              value="{{ slideDefinitionType._id }}"
            >
              {{ slideDefinitionType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="u-marginBm u-flex">
      <div>
        <mat-label class="o-form-label"
          >Preview Video (#t=1.00 in the end to regulate static
          image)</mat-label
        >
        <mat-form-field class="u-marginRxs">
          <input
            type="text"
            matInput
            formControlName="previewVideo"
            placeholder="link"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="u-marginBm u-flex">
      <div>
        <mat-label class="o-form-label">Zendesk link</mat-label>
        <mat-form-field class="u-marginRxs">
          <input
            type="text"
            matInput
            formControlName="infoLink"
            placeholder="link"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="container container--small">
    <h2>Timings</h2>
    <div class="u-marginBm u-flex" formGroupName="timings">
      <div>
        <mat-checkbox formControlName="editable"
          >Editable duration (when there's no variable control for setting
          duration)</mat-checkbox
        >
      </div>
    </div>

    <div class="u-marginBm u-flex" formGroupName="timings">
      <div>
        <mat-label class="o-form-label">Default duration (ms)</mat-label>
        <mat-form-field class="u-marginRxs">
          <input
            type="number"
            matInput
            formControlName="duration"
            placeholder="5000"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div>
        <mat-label class="o-form-label">Inherit from variable</mat-label>
        <mat-form-field class="u-marginRxs">
          <input
            type="text"
            matInput
            formControlName="inheritsFrom"
            placeholder="duration"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="u-marginBm u-flex" formGroupName="garbo">
      <div formGroupName="preview">
        <mat-label class="o-form-label">At time</mat-label>
        <mat-slider
          thumbLabel
          [discrete]="true"
          [displayWith]="formatSliderLabel"
          min="0"
          max="1"
          step="0.01"
        >
          <input matSliderThumb formControlName="atTime" />
        </mat-slider>
      </div>
    </div>

    <div class="u-marginBm" formGroupName="garbo">
      <h3>Clamp (Optional - milliseconds)</h3>
      <div formGroupName="preview">
        <div formGroupName="clamp" class="u-flex">
          <div>
            <mat-label class="o-form-label">Start</mat-label>
            <mat-form-field class="u-marginRxs">
              <input
                type="number"
                matInput
                formControlName="start"
                placeholder="0"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
          <div>
            <mat-label class="o-form-label">End</mat-label>
            <mat-form-field class="u-marginRxs">
              <input
                type="number"
                matInput
                formControlName="end"
                placeholder="7000"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="u-marginBm u-flex">
      <div class="u-width--editor">
        <mat-label class="o-form-label">Variables</mat-label>
        <mat-form-field class="u-marginRxs">
          <textarea
            matInput
            formControlName="variables"
            placeholder="{}"
            autocomplete="off"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="u-marginBm u-flex">
      <div>
        <mat-label class="o-form-label">Access Category</mat-label>
        <mat-form-field class="u-marginRxs">
          <mat-select formControlName="accessCategory">
            <mat-option
              *ngFor="let accessCategory of accessCategories | keyvalue"
              value="{{ accessCategory.value }}"
            >
              {{ accessCategory.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div
      class="u-marginBm"
      *ngIf="slideDefinitionForm.value.accessCategory === 'custom'"
    >
      <h3>Access</h3>
      <app-slide-definition-access-form
        [form]="slideDefinitionForm"
      ></app-slide-definition-access-form>
    </div>

    <div class="u-marginBm u-flex">
      <div>
        <mat-label class="o-form-label">Layout</mat-label>
        <mat-form-field class="u-marginRxs">
          <mat-select formControlName="layout">
            <mat-option> None </mat-option>
            <mat-option
              *ngFor="
                let slideDefinitionLayout of slideDefinitionLayouts$ | async
              "
              value="{{ slideDefinitionLayout._id }}"
            >
              {{ slideDefinitionLayout.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <ng-container *ngIf="(mode$ | async) === modes.Edit">
    <div class="container container--small warning-unsaved-changes">
      <span *ngIf="hasUnsavedFormChanges">Unsaved Changes!</span>
    </div>
  </ng-container>
  <div class="container container--small u-paddingBxl">
    <button
      [disabled]="formLoading || slideDefinitionForm.invalid"
      mat-flat-button
      color="primary"
      (click)="createSlideDefinition()"
      class="u-marginRs"
    >
      Create Slide Definition
    </button>
    <ng-container *ngIf="(mode$ | async) === modes.Edit">
      <button
        [disabled]="formLoading || slideDefinitionForm.invalid"
        mat-flat-button
        color="primary"
        (click)="updateSlideDefinition()"
      >
        Update Slide Definitition
      </button>
      <button
        mat-flat-button
        [disabled]="formLoading"
        color="warn"
        class="u-marginLs"
        (click)="openDeleteDialog()"
      >
        Delete Slide Definition
      </button>
    </ng-container>
  </div>
</form>
<app-error [observable$]="slideDefinition$"></app-error>
