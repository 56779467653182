import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppVersionInterceptor } from './version.interceptor';

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true,
    },
  ],
})
export class AppVersionModule {}
