import { Injectable } from '@angular/core';
import { CwsService } from '@core/services/cws.service';
import { User } from '@models/user';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';

import { AuthClientService } from '@storykit/auth-client';

@Injectable({ providedIn: 'root' })
export class AuthService {
  authenticated$: Observable<boolean>;
  currentUser$: Observable<User | null>;

  private authenticatedSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private auth: AuthClientService,
    private cwsService: CwsService
  ) {
    this.authenticated$ = this.authenticatedSubject.asObservable();
    this.currentUser$ = this.authenticated$
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((authenticated) => {
          if (!authenticated) {
            return of(null);
          }

          return this.cwsService.getCurrentUser();
        })
      )
      .pipe(shareReplay());
  }

  login() {
    return this.auth.login();
  }

  logout() {
    return this.auth.logout();
  }

  isAuthorized(): Observable<boolean> {
    return from(this.getAccessTokenValue()).pipe(map((token) => !!token));
  }

  getAccessToken(): Observable<string | undefined> {
    return from(this.getAccessTokenValue());
  }

  async getAuthorizationHeader(): Promise<string> {
    const token = await this.getAccessTokenValue();

    if (token) {
      return `Bearer ${token}`;
    }

    return '';
  }

  async getRedirectState(location: Location) {
    return this.auth.getRedirectState(location);
  }

  private async getAccessTokenValue(): Promise<string | undefined> {
    const token = await this.auth.getToken();

    this.authenticatedSubject.next(!!token);

    return token;
  }
}
