@if (loading$ | async) {
  Loading..
} @else {
  <form [formGroup]="slideDefinitionLayoutForm">
    <div class="container container--small u-marginTxxl u-flex">
      <div class="u-width--full u-flex u-flex-align--center">
        <h1>Slide Definition</h1>
      </div>
    </div>

    <div class="container container--small">
      <div class="u-marginBm u-flex">
        <div>
          <mat-label class="o-form-label">Display name</mat-label>
          <mat-form-field class="u-marginRxs">
            <input
              type="text"
              matInput
              formControlName="displayName"
              placeholder="Display name"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="u-marginBm u-flex">
        <div class="u-width--editor">
          <mat-label class="o-form-label">Layout</mat-label>
          <mat-form-field class="u-marginRxs">
            <textarea
              matInput
              formControlName="layout"
              placeholder="{}"
              autocomplete="off"
            ></textarea>
            @if (
              slideDefinitionLayoutForm.get('layout')?.hasError('jsonInvalid')
            ) {
              <mat-error>Invalid JSON</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="container container--small u-paddingBxl">
      @if ((mode$ | async) === modes.Add) {
        <button
          [disabled]="formLoading || slideDefinitionLayoutForm.invalid"
          mat-flat-button
          color="primary"
          (click)="createSlideDefinitionLayout()"
          class="u-marginRs"
        >
          Create Slide Definition Layout
        </button>
      } @else if ((mode$ | async) === modes.Edit) {
        <button
          [disabled]="formLoading || slideDefinitionLayoutForm.invalid"
          mat-flat-button
          color="primary"
          (click)="updateSlideDefinitionLayout()"
        >
          Update Slide Definitition Layout
        </button>
        <button
          mat-flat-button
          [disabled]="formLoading"
          color="warn"
          class="u-marginLs"
          (click)="openDeleteDialog()"
        >
          Delete Slide Definition Layout
        </button>
      }
    </div>
  </form>
}
<app-error [observable$]="slideDefinitionLayout$"></app-error>
