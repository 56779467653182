import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { ElementId } from '@storykit/constants';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styles: [],
})
export class ConfirmDialogComponent {
  elementId = ElementId;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action: string;
      subject: string;
      amount?: number;
      name?: string;
    }
  ) {}

  static open(
    dialog: MatDialog,
    data: { action: string; subject: string; amount?: number; name?: string }
  ) {
    return dialog.open(ConfirmDialogComponent, {
      data,
    });
  }

  lowercase(str: string) {
    return str.toLowerCase();
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + this.lowercase(str.slice(1));
  }
}
